
<template>
	<div class="no-print">
		<v-app-bar color="primary">
			<template v-slot:prepend>
				<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			</template>
			<v-app-bar-title>
				<v-btn variant="text" to="/">
					جیهانی قایش
				</v-btn>
			</v-app-bar-title>
			<v-spacer></v-spacer>
			<v-btn icon @click="logout">
				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" temporary>
			<v-list-item>
				جیهانی قایش
			</v-list-item>
			<v-divider></v-divider>
			<v-list density="compact" nav>
				<v-list-item v-for="(item, index) in navigation_links" :key="index" :to="item.path">
					{{ item.title }}
				</v-list-item>
			</v-list>
		</v-navigation-drawer>
	</div>
</template>
<script setup>
	import { ref, computed } from 'vue';
	import { useStore } from 'vuex';
	const store = useStore();
	let drawer = ref(false);
	const logout = () => {
		store.dispatch('logout');
	}
	const navigation_links = computed(() => {
		const content = [

			{
				path: '/bank_account_transfers-list',
				title: 'ئاڵوگۆری هەژمارەکان  ',
			},

			{
				path: '/bank_accounts-list',
				title: 'هەژمارەکان   ',
			},

			{
				path: '/categories-list',
				title: 'جۆرەکانی کاڵا   ',
			},

			{
				path: '/customer_payments-list',
				title: 'پارەدانی کڕیار',
			},

			{
				path: '/customers-list',
				title: 'کڕیارەکان   ',
			},

			{
				path: '/expense_categories-list',
				title: 'جۆرەکانی خەرجی  ',
			},

			{
				path: '/expenses-list',
				title: 'خەرجییەکان  ',
			},

			{
				path: '/inventories-list',
				title: 'کۆگاکان  ',
			},

			{
				path: '/inventory_items-list',
				title: 'کاڵاکانی کۆگاکان   ',
			},
			{
				path: '/inventory_items-list_view',
				title: 'گەڕانی کاڵا  ',
			},

			// {
			//     path : '/invoice_items-list',
			//     title : 'کاڵاکانی پسوڵەکان',
			// },

			{
				path: '/invoices-list',
				title: 'فڕۆشتن',
			},
			{
				path: '/invoices-view',
				title: 'گەڕانی فڕۆشتن',
			},

			{
				path: '/item_moves-list',
				title: 'گۆڕینی شوێنی کاڵا  ',
			},

			{
				path: '/item_stocks-list',
				title: 'کاڵا خەساربووەکان  ',
			},

			{
				path: '/items-list',
				title: 'کاڵاکان    ',
			},

			// {
			//     path : '/supplier_invoice_items-list',
			//     title : 'Supplier Invoice Items',
			// },

			{
				path: '/supplier_invoices-list',
				title: ' وەسڵی دابینکەران    ',
			},

			{
				path: '/supplier_payments-list',
				title: 'پارەدانی دابینکەران',
			},

			{
				path: '/suppliers-list',
				title: 'دابینکەران   ',
			},

			{
				path: '/users-list',
				title: 'بەکارهێنەران',
			},
			{
				path: '/store_informations-list',
				title: 'Store Informations',
			},

		];
		return content;
	});

</script>
        