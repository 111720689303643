
import { createRouter, createWebHistory } from 'vue-router'
import {
    auth
} from '../firebase'

const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta: {
            auth: false,
            title: 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },

    {
        path: '/bank_account_transfers-list',
        name: 'ListBank_account_transfers',
        component: () => import('./../views/Bank_account_transfers/List.vue'),
        meta: {
            auth: true,
            title: 'Bank_account_transfers List'
        }
    },
    {
        path: '/bank_account_transfers-list/:id',
        name: 'ViewBank_account_transfers',
        component: () => import('./../views/Bank_account_transfers/Edit.vue'),
        meta: {
            auth: true,
            title: 'Bank_account_transfers Edit'
        }
    },
    {
        path: '/bank_accounts-list',
        name: 'ListBank_accounts',
        component: () => import('./../views/Bank_accounts/List.vue'),
        meta: {
            auth: true,
            title: 'Bank_accounts List'
        }
    },
    {
        path: '/bank_accounts-list/:id',
        name: 'ViewBank_accounts',
        component: () => import('./../views/Bank_accounts/Edit.vue'),
        meta: {
            auth: true,
            title: 'Bank_accounts Edit'
        }
    },
    {
        path: '/categories-list',
        name: 'ListCategories',
        component: () => import('./../views/Categories/List.vue'),
        meta: {
            auth: true,
            title: 'Categories List'
        }
    },
    {
        path: '/categories-list/:id',
        name: 'ViewCategories',
        component: () => import('./../views/Categories/Edit.vue'),
        meta: {
            auth: true,
            title: 'Categories Edit'
        }
    },
    {
        path: '/customer_payments-list',
        name: 'ListCustomer_payments',
        component: () => import('./../views/Customer_payments/List.vue'),
        meta: {
            auth: true,
            title: 'Customer_payments List'
        }
    },
    {
        path: '/customer_payments-list/:id',
        name: 'ViewCustomer_payments',
        component: () => import('./../views/Customer_payments/Edit.vue'),
        meta: {
            auth: true,
            title: 'Customer_payments Edit'
        }
    },
    {
        path: '/customers-list',
        name: 'ListCustomers',
        component: () => import('./../views/Customers/List.vue'),
        meta: {
            auth: true,
            title: 'Customers List'
        }
    },
    {
        path: '/customers-list/:id',
        name: 'ViewCustomers',
        component: () => import('./../views/Customers/Edit.vue'),
        meta: {
            auth: true,
            title: 'Customers Edit'
        }
    },
    {
        path: '/customers-view/:id',
        name: 'ViewOneCustomers',
        component: () => import('./../views/Customers/ViewOne.vue'),
        meta: {
            auth: true,
            title: 'Customers View'
        }
    },
    {
        path: '/customers-view-edit/:id',
        name: 'ViewOneEditCustomers',
        component: () => import('./../views/Customers/EditOne.vue'),
        meta: {
            auth: true,
            title: 'Customers View One'
        }
    },
    {
        path: '/expense_categories-list',
        name: 'ListExpense_categories',
        component: () => import('./../views/Expense_categories/List.vue'),
        meta: {
            auth: true,
            title: 'Expense_categories List'
        }
    },
    {
        path: '/expense_categories-list/:id',
        name: 'ViewExpense_categories',
        component: () => import('./../views/Expense_categories/Edit.vue'),
        meta: {
            auth: true,
            title: 'Expense_categories Edit'
        }
    },
    {
        path: '/expenses-list',
        name: 'ListExpenses',
        component: () => import('./../views/Expenses/List.vue'),
        meta: {
            auth: true,
            title: 'Expenses List'
        }
    },
    {
        path: '/expenses-list/:id',
        name: 'ViewExpenses',
        component: () => import('./../views/Expenses/Edit.vue'),
        meta: {
            auth: true,
            title: 'Expenses Edit'
        }
    },
    {
        path: '/inventories-list',
        name: 'ListInventories',
        component: () => import('./../views/Inventories/List.vue'),
        meta: {
            auth: true,
            title: 'Inventories List'
        }
    },
    {
        path: '/inventories-list/:id',
        name: 'ViewInventories',
        component: () => import('./../views/Inventories/Edit.vue'),
        meta: {
            auth: true,
            title: 'Inventories Edit'
        }
    },
    {
        path: '/inventory_items-list',
        name: 'ListInventory_items',
        component: () => import('./../views/Inventory_items/List.vue'),
        meta: {
            auth: true,
            title: 'Inventory_items List'
        }
    },
    {
        path: '/inventory_items-list_view',
        name: 'ListInventory_items_view',
        component: () => import('./../views/Inventory_items/View.vue'),
        meta: {
            auth: true,
            title: 'Inventory_items List view'
        }
    },

    {
        path: '/inventory_items-list/:id',
        name: 'ViewInventory_items',
        component: () => import('./../views/Inventory_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Inventory_items Edit'
        }
    },
    {
        path: '/invoice_items-list',
        name: 'ListInvoice_items',
        component: () => import('./../views/Invoice_items/List.vue'),
        meta: {
            auth: true,
            title: 'Invoice_items List'
        }
    },
    {
        path: '/invoice_items-list/:id',
        name: 'ViewInvoice_items',
        component: () => import('./../views/Invoice_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Invoice_items Edit'
        }
    },
    {
        path: '/invoices-list',
        name: 'ListInvoices',
        component: () => import('./../views/Invoices/List.vue'),
        meta: {
            auth: true,
            title: 'Invoices List'
        }
    },
    {
        path: '/invoices-view',
        name: 'ViewsInvoices',
        component: () => import('./../views/Invoices/View.vue'),
        meta: {
            auth: true,
            title: 'Invoices Views'
        }
    },
    {
        path: '/invoices-list/:id',
        name: 'ViewInvoices',
        component: () => import('./../views/Invoices/Edit.vue'),
        meta: {
            auth: true,
            title: 'Invoices Edit'
        }
    },
    {
        path: '/invoices-view-one/:id',
        name: 'ViewOneInvoices',
        component: () => import('./../views/Invoices/View_one.vue'),
        meta: {
            auth: true,
            title: 'Invoices View One'
        }
    },
    {
        path: '/item_moves-list',
        name: 'ListItem_moves',
        component: () => import('./../views/Item_moves/List.vue'),
        meta: {
            auth: true,
            title: 'Item_moves List'
        }
    },
    {
        path: '/item_moves-list/:id',
        name: 'ViewItem_moves',
        component: () => import('./../views/Item_moves/Edit.vue'),
        meta: {
            auth: true,
            title: 'Item_moves Edit'
        }
    },
    {
        path: '/item_stocks-list',
        name: 'ListItem_stocks',
        component: () => import('./../views/Item_stocks/List.vue'),
        meta: {
            auth: true,
            title: 'Item_stocks List'
        }
    },
    {
        path: '/item_stocks-list/:id',
        name: 'ViewItem_stocks',
        component: () => import('./../views/Item_stocks/Edit.vue'),
        meta: {
            auth: true,
            title: 'Item_stocks Edit'
        }
    },
    {
        path: '/items-list',
        name: 'ListItems',
        component: () => import('./../views/Items/List.vue'),
        meta: {
            auth: true,
            title: 'Items List'
        }
    },
    {
        path: '/items-list-qaish',
        name: 'ListItemsQaish',
        component: () => import('./../views/Items/ListQaish.vue'),
        meta: {
            auth: true,
            title: 'Items List Qaish'
        }
    },
    {
        path: '/items-print',
        name: 'PrintItems',
        component: () => import('./../views/Items/Print.vue'),
        meta: {
            auth: true,
            title: 'Items Print'
        }
    },
    {
        path: '/items-search-category',
        name: 'ViewItemsCate',
        component: () => import('./../views/Items/Search.vue'),
        meta: {
            auth: true,
            title: 'View Items Cate'
        }
    },
    {
        path: '/items-list/:id',
        name: 'ViewItems',
        component: () => import('./../views/Items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Items Edit'
        }
    },
    {
        path: '/supplier_invoice_items-list',
        name: 'ListSupplier_invoice_items',
        component: () => import('./../views/Supplier_invoice_items/List.vue'),
        meta: {
            auth: true,
            title: 'Supplier_invoice_items List'
        }
    },
    {
        path: '/supplier_invoice_items-list/:id',
        name: 'ViewSupplier_invoice_items',
        component: () => import('./../views/Supplier_invoice_items/Edit.vue'),
        meta: {
            auth: true,
            title: 'Supplier_invoice_items Edit'
        }
    },
    {
        path: '/supplier_invoices-list',
        name: 'ListSupplier_invoices',
        component: () => import('./../views/Supplier_invoices/List.vue'),
        meta: {
            auth: true,
            title: 'Supplier_invoices List'
        }
    },
    {
        path: '/supplier_invoices-list/:id',
        name: 'ViewSupplier_invoices',
        component: () => import('./../views/Supplier_invoices/Edit.vue'),
        meta: {
            auth: true,
            title: 'Supplier_invoices Edit'
        }
    },
    {
        path: '/supplier_invoices-one/:id',
        name: 'EditSupplier_invoices',
        component: () => import('./../views/Suppliers/EditOne.vue'),
        meta: {
            auth: true,
            title: 'Supplier_invoices Edit One'
        }
    },
    {
        path: '/supplier_payments-list',
        name: 'ListSupplier_payments',
        component: () => import('./../views/Supplier_payments/List.vue'),
        meta: {
            auth: true,
            title: 'Supplier_payments List'
        }
    },
    {
        path: '/supplier_payments-list/:id',
        name: 'ViewSupplier_payments',
        component: () => import('./../views/Supplier_payments/Edit.vue'),
        meta: {
            auth: true,
            title: 'Supplier_payments Edit'
        }
    },
    {
        path: '/suppliers-list',
        name: 'ListSuppliers',
        component: () => import('./../views/Suppliers/List.vue'),
        meta: {
            auth: true,
            title: 'Suppliers List'
        }
    },
    {
        path: '/suppliers-view',
        name: 'ListSuppliersView',
        component: () => import('./../views/Suppliers/View.vue'),
        meta: {
            auth: true,
            title: 'Suppliers View'
        }
    },
    {
        path: '/suppliers-view/:id',
        name: 'ViewOneSuppliers',
        component: () => import('./../views/Suppliers/EditView.vue'),
        meta: {
            auth: true,
            title: 'Suppliers EditView'
        }
    },
    {
        path: '/suppliers-list/:id',
        name: 'ViewSuppliers',
        component: () => import('./../views/Suppliers/Edit.vue'),
        meta: {
            auth: true,
            title: 'Suppliers Edit'
        }
    },
    {
        path: '/users-list',
        name: 'ListUsers',
        component: () => import('./../views/Users/List.vue'),
        meta: {
            auth: true,
            title: 'Users List'
        }
    },
    {
        path: '/users-list/:id',
        name: 'ViewUsers',
        component: () => import('./../views/Users/Edit.vue'),
        meta: {
            auth: true,
            title: 'Users Edit'
        }
    }, {
        path: '/store_informations-list',
        name: 'ListStore_informations',
        component: () => import('./../views/Store_informations/List.vue'),
        meta: {
            auth: true,
            title: 'Store_informations List'
        }
    },
    {
        path: '/store_informations-list/:id',
        name: 'ViewStore_informations',
        component: () => import('./../views/Store_informations/Edit.vue'),
        meta: {
            auth: true,
            title: 'Store_informations Edit'
        }
    },
    {
        path: '/report',
        name: 'Reports',
        component: () => import('./../views/Reports/Report.vue'),
        meta: {
            auth: true,
            title: 'report'
        }
    },
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

// router.beforeEach((to, from, next) => {
    // auth.onAuthStateChanged(user => {
    //     if (to.matched.some(record => record.meta.auth) && !user) {
    //         next({
    //             path: '/login',
    //             query: {
    //                 redirect: to.fullPath
    //             }
    //         })
    //     } else if(!to.matched.some(record => record.meta.auth) && !user){
    //         next()
    //     } else {
    //         next()
    //     }
    // })

//     next()
// })

router.afterEach((to, from) => {
    // Check if the route has a redirect query parameter
    if (to.query.redirect && to.matched.some(record => record.meta.auth)) {
        // If the user is authenticated, redirect to the original requested route
        if (auth.currentUser) {
            router.push(to.query.redirect);
        }
    }
});
export default router
