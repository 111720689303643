
import { createStore } from 'vuex'
import axios from 'axios'
import languages from './languages.json'
import moment from 'moment'
import { auth } from '../firebase'
import router from '../router'
import Login from '../views/Login.vue'

export default createStore({

    state: {

        languages: languages,
        language: languages[0],
        auth: {
            isAuth: false,
            user: null
        },
        init_state: false,
        user: {},
        // myTheme: {
        //     dark: true,
        //     colors: {
        //         primary: '#1976D2',
        //         secondary: '#424242',
        //         accent: '#82B1FF',
        //         error: '#ba3c3c',
        //         info: '#099FFF',
        //         success: '#5fa052',
        //         warning: '#FF6600',
        //     },
        // },

    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,
        auth: state => state.auth,
        init_state: state => state.init_state,
        user: state => state.user,
        exchange_rate: state => state.exchange_rate,
    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },
        setAuth(state, payload) {
            state.auth.user = payload.user
            state.auth.isAuth = true
            localStorage.setItem('auth', JSON.stringify(auth))
            // localStorage.setItem('user', JSON.stringify(payload.user))
        },
        // SET_THEME(state, payload) {
        //     state.myTheme.dark = payload
        // }
    },
    actions: {


        init(context, uid) {
            const id = uid
            axios.get('init/' + id)
                .then(r => {
                    if (r.status == 200 && r.data) {

                        context.commit('setAuth', {
                            user: r.data.user,

                        })
                        // axios.defaults.headers.common['Authorization'] = `Bearer ${r.data.token}`;
                        // context.commit('setUsersList', r.data.users)
                        // context.commit('setCustomersList', r.data.customers)
                        // context.commit('setUsersList', r.data.users)
                        // context.commit('setSalaryReportList', r.data.salary_report)
                        // context.commit('setOrdersList', r.data.orders)
                        // context.commit('setStoreInformation', r.data.store_information)
                        // context.commit('setItemColorsList', r.data.item_colors)
                        // context.commit('setItemTypesList', r.data.item_types)
                        // context.commit('setItemSizesList', r.data.item_sizes)
                        // context.commit('setShopItemsList', r.data.shop_items)
                        // context.commit('setDriversList', r.data.drivers)
                        context.state.user = r.data.user
                        context.state.init_state = true
                        context.state.exchange_rate = r.data.exchange_rate

                    } else {
                        console.log("error")
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        logout(context) {
            console.log("logout")
            context.state.init_state = false
            context.state.auth = {
                isAuth: false,
                user: null
            }

            localStorage.removeItem('auth')
            auth.signOut()
            router.push({ name: 'Login' })
        },
        // toggleTheme(context) {
        //     let theme = !context.state.myTheme.dark
        //     console.log(theme)
        //     context.commit('SET_THEME', theme);

        // }
    },
})
