
<template>
	<div>
		<v-container fluid class="login-container">
			<v-row class="login-container d-flex align-center justify-center">

				<v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" order-sm="1" order-md="2">
			
				</v-col>

				<v-col cols="12" xl="4" lg="4" md="6" sm="12" xs="12" class="bg-main-1 d-flex align-center" order-sm="2" order-md="1">
					<v-card width="100%" color="transparent" class="elevation-0 px-2">

						<v-card-subtitle>
							جیهانی قایش
						</v-card-subtitle>
						<v-card-text class="mt-3">
							<form @submit.prevent="login">
								<v-text-field autocomplete="false" density="compact" class="mx-1" variant="outlined" required label="ئیمەیڵ" v-model="user.email">
								</v-text-field>
								<v-text-field v-model="user.password" autocomplete="false" density="compact" class="mx-1" variant="outlined" required type="password" label="وشەی نهێنی">
								</v-text-field>
								<v-btn type="submit" block color="primary" :loading="btn_loading" style="color:white">
									چوونە ژوورەوە</v-btn>
							</form>
						</v-card-text>
						<v-card-text class="text-center">
							<h3>
								<v-btn to="/reset-password" text>پاسۆردت بیرچۆتەوە</v-btn>
							</h3>
							<br>
						</v-card-text>

					</v-card>
				</v-col>

			</v-row>
			
		</v-container>

	</div>
</template>
<script setup>
	import {
		auth,
		signInWithEmailAndPassword
	} from '../firebase'
	import {
		onMounted,
		ref
	} from 'vue'
import { toast } from "vue3-toastify";
    
	// const logbw = require('@/assets/nawandi_nwe_logo.png')
	// const topit = require('@/assets/login.png');

	const login = () => {
		signInWithEmailAndPassword(auth, user.value.email, user.value.password)
			.then((r) => {
				localStorage.setItem('auth', 'auth')
				window.location.href = '/'
			})
			.catch(err => {
				  toast('ڕاست نییە ناتوانیت بچیتە هەژمارەکەتەوە' , {
                type: 'error',
                
            })
				console.log(err)
			})
	}

	onMounted(() => {
		// if (localStorage.getItem('auth')) {
		//     window.location.href = '/'            
		// }

		// if (process.env.NODE_ENV === 'development') {
		//     user.value.email = 'admin@void.krd'
		//     user.value.password = '12345678'
		// }
	})


	let user = ref({
		email: '',
		password: ''
	})
</script>
<style lang="scss" scoped>
	.login-container {
		height: 80vh;
	}
</style>
        