
<template>
	<v-app>
		<v-locale-provider rtl>
			<div v-if="$store.getters.auth.isAuth && $store.state.init_state">

				<APPNavbar></APPNavbar>
				<v-main class="px-2">
					<router-view />
				</v-main>
			</div>
			<div v-else>
				<v-main>
					<router-view />

				</v-main>
			</div>
		</v-locale-provider>
	</v-app>
</template>
<script setup>
	import { ref, onMounted } from 'vue';

	import APPNavbar from './components/Navbar';
	import { auth } from './firebase';

	import { useStore } from 'vuex';

	const store = useStore();
	onMounted(() => {
		auth.onAuthStateChanged(user => {
			if (user) {
				console.log(user.uid)
				store.dispatch('init', user.uid);
			} else {
				store.dispatch('logout');
			}
		})
	});


	// Use onMounted instead of mounted lifecycle hook
	// onMounted(() => {
		// auth.onAuthStateChanged(user => {
		//   if (user) {
		//   } else {
		//   }
		// });
	// });
</script>
<style>
	.report-table {
		width: 100%;
		border-collapse: collapse;
		border: 1px solid;

		text-align: start;
	}
	.report-table th {
		padding: 5px;
		border: 1px solid;
		text-align: start !important;
		background: gray;
		color: white;
	}
	.report-table td {
		padding: 5px;
		border: 1px solid;
		/* text-align: left !important; */
	}
	@media print {
		.no-print {
			display: none;
		}
		.only-print {
			display: block !important;
		}
	}
	.only-print {
		display: none;
	}
    </style>
      
        