
import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth,signInWithEmailAndPassword , sendPasswordResetEmail } from "firebase/auth";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBg2rjabHmOjLfYGn20OoUPA_U3xlBv_Yc",
    authDomain: "jihanyqaish-bbc78.firebaseapp.com",
    projectId: "jihanyqaish-bbc78",
    storageBucket: "jihanyqaish-bbc78.appspot.com",
    messagingSenderId: "695481748652",
    appId: "1:695481748652:web:1d452a09c93b94936aa309",
    measurementId: "G-02ZQCVMQYX"
};
const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth , app , signInWithEmailAndPassword , sendPasswordResetEmail}
        